// モジュールとなるjsファイルの読み込み
// analytics
import {module_analytics} from './js/googleAnalytics';

// vendors
import {module_jquery_easing} from './js/jquery.easing';
import {module_slick} from './js/slick.min';
//import {module_vegas} from './js/vegas.min';
import {module_magnific} from './js/jquery.magnific-popup.min';
import {module_scroll} from './js/scrollAnimation';

// pages
import {module_common} from './js/common';
import {module_index} from './js/index';
import {module_news} from './js/news';


// モジュール先にある関数を実行
module_analytics();

module_jquery_easing();
module_slick();
//module_vegas();
module_magnific();
module_scroll();

module_common();
module_index();
module_news();

// Sassファイルの読み込み
//import './sass/style.scss';