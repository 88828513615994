export function module_news() {
	$(function() {
		//--------------------------------------------------------
		//アーカイブ開閉
		//--------------------------------------------------------
		$('.blogMonthList').hide();
		$('.blogWidgetList__year').click(function(){
			$(this).next("ul").slideToggle();
			$(this).parent("li").toggleClass("open");
		});

		//--------------------------------------------------------
		//Magnific Popup Setteing
		//--------------------------------------------------------
		//拡張子の配列
		var arrayExtensions = ['.jpg', '.jpeg', '.gif', '.png'];
		//クラス名を入れる配列
		var extensionsLength = [];
		//クラス名＋拡張子をループ
		$.each( arrayExtensions, function() {
			var magnificClass = '.entryBody a[href$="' + this + '"]';
			//クラス名を配列に入れる
			extensionsLength.push(magnificClass);
		});
		//カンマ区切りでクラス名の文字列を出力
		var extensions = extensionsLength.join();

		$(extensions).each(function() {
			$(this).magnificPopup({
				type: 'image',
				mainClass: 'mfp-fade',
				removalDelay: 500,
				preloader: false,
				gallery:{enabled:true}
			});
		});
	});
}