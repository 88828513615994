export function module_scroll() {
	$(function() {
		//--------------------------------------------------------
		// Scroll Event
		//--------------------------------------------------------
		const obj = $(".scrollAnimation-obj");
		const upIn = $(".scrollAnimation-up");
		const downIn = $(".scrollAnimation-down");
		const leftIn = $(".scrollAnimation-left");
		const rightIn = $(".scrollAnimation-right");
		const listIn = $(".scrollAnimation-list");

		$(window).on('load scroll',function(){
			const scroll = $(window).scrollTop();
			const windowH = $(window).height();

			obj.each(function(){
				const objPos = $(this).offset().top;
				if(scroll > objPos - windowH + 100 || objPos < windowH){
					$(this).css({
						'opacity': '1'
					});
				}
			});
			upIn.each(function(){
				const objPos = $(this).offset().top;
				if(scroll > objPos - windowH + 100 || objPos < windowH){
					$(this).css({
						'opacity': '1',
						'transform': 'translate(0, 0)'
					});
				}
			});
			downIn.each(function(){
				const objPos = $(this).offset().top;
				if(scroll > objPos - windowH + 100 || objPos < windowH){
					$(this).css({
						'opacity': '1',
						'transform': 'translate(0, 0)'
					});
				}
			});
			leftIn.each(function(){
				const objPos = $(this).offset().top;
				if(scroll > objPos - windowH + 100 || objPos < windowH){
					$(this).css({
						'opacity': '1',
						'transform': 'translate(0, 0)'
					});
				}
			});
			rightIn.each(function(){
				const objPos = $(this).offset().top;
				if(scroll > objPos - windowH + 100 || objPos < windowH){
					$(this).css({
						'opacity': '1',
						'transform': 'translate(0, 0)'
					});
				}
			});
			listIn.each(function(){
				const objPos = $(this).offset().top;
				if(scroll > objPos - windowH + 100 || objPos < windowH){
					var lists = $(this).children();
					lists.each(function(i){
						$(this).delay(100 * i).animate({
							'opacity': '1',
							'transform': 'translate(0, 0)'
						});
					});
				}
			});
		});
	});
}